<template>
  <div id="cnpj-form">
    <div class="header">
      <div>
        <p class="title">
          {{ isEdit ? 'Editar' : 'Novo' }} CNPJ
          <span id="help-icon" class="icon-box">
            <v-help-circle class="icon stroke mb-2" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            Em caso de dúvidas, consulte um contador
          </b-tooltip>
        </p>

        <div class="breadcrumbs">
          <span @click="cancelForm"> Contas </span>
          <ChevronRight class="chevron" />
          <span class="active"> {{ isEdit ? 'Editar' : 'Novo' }} CNPJ </span>
        </div>
      </div>

      <div class="header-actions">
        <p @click="deleteAccount" v-if="isEdit" class="delete-button mb-0">
          Excluir conta
        </p>

        <b-button variant="primary" @click.prevent="saveCNPJForm">
          {{ isEdit ? 'Atualizar CNPJ' : 'Salvar novo CNPJ' }}
        </b-button>
      </div>
    </div>

    <validation-observer ref="createNfForm">
      <b-row>
        <b-col cols="3">
          <div class="form-group">
            <label for="cnpj">CNPJ *</label>
            <validation-provider
              mode="eager"
              name="cnpj"
              rules="required|cnpj"
              vid="cnpj"
              v-slot="{ touched, errors, dirty }"
            >
              <the-mask
                :masked="false"
                autocomplete="off"
                id="cnpj"
                type="text"
                v-model="accountData.cnpj"
                placeholder="00.000.000/0000-00"
                class="form-control"
                :mask="'##.###.###/####-##'"
                :disabled="isEdit"
                :class="{ error: errors.length > 0 && dirty }"
              />

              <span
                class="error-message"
                v-if="validated && accountData.cnpj && errors.length > 0"
              >
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.cnpj"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="9">
          <div class="form-group">
            <label for="razao_social">Razão Social *</label>
            <validation-provider
              mode="lazy"
              rules="required"
              name="razão social"
              v-slot="{ touched, errors }"
            >
              <b-form-input
                autocomplete="off"
                id="razao_social"
                type="text"
                v-model="accountData.razao_social"
                placeholder="Razão social"
                class="form-control"
              />

              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.razao_social"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <div class="form-group">
            <label for="senha_certificado_digital">
              Senha Certificado Digital *
            </label>

            <validation-provider
              mode="lazy"
              name="senha certificado digital"
              v-slot="{ touched, errors }"
            >
              <b-form-input
                autocomplete="off"
                id="senha_certificado_digital"
                type="password"
                v-model="accountData.senha_certificado_digital"
                class="form-control"
              />

              <span
                class="error-message"
                v-if="!isEdit && touched && errors.length > 0"
              >
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="
                  !isEdit &&
                  validated &&
                  !accountData.arquivo_certificado_base64
                "
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="8">
          <div class="form-group">
            <label for="certificado_digital">Certificado Digital *</label>
            <validation-provider
              ref="certificadoDigital"
              mode="lazy"
              rules="required|ext:p12,pfx,pem,crt"
              name="Certificado Digital"
              v-slot="{ touched, errors }"
            >
              <input
                type="file"
                id="certificado_digital"
                style="display: none"
                ref="fileInput"
                accept=".p12, .pfx, .pem, .crt"
                @change="uploadCertificadoDigital($event)"
              />
              <div
                v-if="!base64Data"
                for="certificado_digital"
                class="input-file__label"
                @click="handleFileInputClick"
              >
                <Clip />
                Anexar arquivo
              </div>
              <div v-if="base64Data" class="input-file__label--selected">
                {{ fileName }}
                <div class="close__container" @click="deleteCertificadoDigital">
                  <Close />
                </div>
              </div>

              <span
                class="error-message"
                v-if="
                  accountData.arquivo_certificado_base64 && errors.length > 0
                "
                >{{ errors[0] }}</span
              >

              <span
                class="error-message"
                v-if="
                  !isEdit &&
                  validated &&
                  !accountData.arquivo_certificado_base64
                "
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <p class="subtitle">Identificação</p>
      <b-row>
        <b-col cols="4">
          <div class="form-group">
            <label for="nome_fantasia">Nome Fantasia *</label>

            <validation-provider
              mode="lazy"
              rules="required"
              name="nome fantasia"
              v-slot="{ touched, errors }"
            >
              <b-form-input
                autocomplete="off"
                id="nome_fantasia"
                type="text"
                v-model="accountData.nome_fantasia"
                placeholder="Nome fantasia"
                class="form-control"
              />

              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.nome_fantasia"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="form-group">
            <label for="inscricao_municipal">Inscrição Municipal *</label>

            <validation-provider
              mode="lazy"
              rules="required"
              name="inscrição municipal"
              v-slot="{ touched, errors }"
            >
              <b-form-input
                autocomplete="off"
                id="inscricao_municipal"
                type="text"
                v-model="accountData.inscricao_municipal"
                placeholder="11104"
                class="form-control"
              />

              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.inscricao_municipal"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="3">
          <b-form-group id="nfStatus">
            <label for="nfStatus" id="input-title">Regime tributário *</label>
            <Autocomplete
              v-model="accountData.regime_tributario"
              :options="regimeTributarioOptions"
              placeholder="Selecionar"
              @input="setRegimeTriburaio"
            />
            <span
              class="error-message"
              v-if="validated && !touched && !accountData.regime_tributario"
            >
              Campo obrigatório
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <div class="form-group">
            <label for="inscricao_municipal">
              Inscrição Estadual
              <span class="help">(opcional)</span>
            </label>

            <validation-provider mode="lazy" name="inscrição estadual">
              <b-form-input
                autocomplete="off"
                id="inscricao_estadual"
                type="number"
                v-model="accountData.inscricao_estadual"
                placeholder="11104"
                class="form-control"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      

      <p class="subtitle">Dados para contato</p>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="email">E-mail *</label>

            <validation-provider
              mode="lazy"
              rules="required|email"
              name="e-mail"
              v-slot="{ touched, errors }"
            >
              <b-form-input
                autocomplete="off"
                id="email"
                type="text"
                v-model="accountData.email"
                class="form-control"
              />

              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.email"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="email">
              Telefone
              <span class="help">(opcional)</span>
            </label>

            <validation-provider mode="lazy" name="telefone">
              <b-form-input
                autocomplete="off"
                id="telefone"
                type="text"
                v-model="accountData.telefone"
                placeholder="(00) 00000-0000"
                class="form-control"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <p class="title__l">Endereço</p>
      <b-row>
        <b-col cols="2">
          <div class="form-group">
            <label for="zipcode">CEP *</label>
            <validation-provider
              name="CEP"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="cep"
                type="text"
                v-model="accountData.cep"
                class="form-control"
                @blur="searchCEP"
                v-mask="'#####-###'"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.cep"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="address">Logradouro *</label>
            <validation-provider
              name="logradouro"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="address"
                type="text"
                v-model="accountData.logradouro"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.logradouro"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="1">
          <div class="form-group">
            <label for="number">Nº *</label>
            <validation-provider
              name="número"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="number"
                type="text"
                ref="addressNumber"
                v-model="accountData.numero"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message p-0 pl-1 w-130px"
                v-if="validated && !touched && !accountData.numero"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="form-group">
            <label for="complement">Complemento</label>
            <input
              autocomplete="off"
              id="complement"
              type="text"
              v-model="accountData.complemento"
              class="form-control"
            />
          </div>
        </b-col>

        <b-col cols="4">
          <div class="form-group">
            <label for="neighborhood">Bairro *</label>
            <validation-provider
              name="bairro"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="neighborhood"
                type="text"
                v-model="accountData.bairro"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.bairro"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group">
            <label for="city">Cidade *</label>
            <validation-provider
              name="cidade"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="city"
                type="text"
                v-model="accountData.municipio"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.municipio"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="form-group">
            <label for="uf">Estado *</label>
            <validation-provider
              name="estado"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <multiselect
                id="uf"
                :options="statesOptions"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                placeholder="UF"
                v-model="accountData.uf"
                class="with-border"
                :class="{ error: errors.length > 0 && dirty }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.uf"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <p class="subtitle">Responsável da conta</p>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="nome_responsavel">Nome completo *</label>
            <validation-provider
              name="nome"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="nome_responsavel"
                type="text"
                v-model="accountData.nome_responsavel"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.nome_responsavel"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>

          <p
            @click="openContador"
            class="contador-link"
            v-if="!showContadorBox"
          >
            + Adicionar contador (opcional)
          </p>

          <div v-if="showContadorBox">
            <b-row>
              <b-col cols="7">
                <div class="form-group">
                  <label for="cpf_cnpj_contabilidade">CPF/CNPJ Contador</label>
                  <validation-provider name="cpf/cnpj contador">
                    <input
                      autocomplete="off"
                      id="cpf_cnpj_contabilidade"
                      type="text"
                      v-model="accountData.cpf_cnpj_contabilidade"
                      class="form-control"
                      placeholder="000.000.000-00"
                      v-mask="['###.###.###-##', '##.###.###-####-##']"
                    />
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <validation-provider
              name="cpf"
              rules="required|cpf"
              v-slot="{ errors, touched, dirty }"
            >
              <label for="cpf_responsavel">CPF *</label>
              <the-mask
                :masked="false"
                autocomplete="off"
                id="cpf_responsavel"
                type="text"
                v-model="accountData.cpf_responsavel"
                class="form-control"
                placeholder="000.000.000-00"
                :mask="'###.###.###-##'"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span
                class="error-message"
                v-if="
                  validated && accountData.cpf_responsavel && errors.length > 0
                "
              >
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.cpf_responsavel"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="name">Login Prefeitura</label>
            <validation-provider
              name="login prefeitura"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="login_responsavel"
                type="text"
                v-model="accountData.login_responsavel"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="senha_responsavel">Senha Prefeitura</label>
            <validation-provider
              name="senha prefeitura"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="senha_responsavel"
                type="password"
                v-model="accountData.senha_responsavel"
                class="form-control"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <p class="title__xl">
        Dados do Prestador
        <span id="help-icon-prestador" class="icon-box">
          <v-help-circle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon-prestador" placement="bottom">
          Em caso de dúvidas, consulte um contador
        </b-tooltip>
      </p>

      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="codigo_cnae">CNAE *</label>
            <validation-provider
              name="cnae"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="codigo_cnae"
                type="number"
                mask="#######"
                v-model="accountData.codigo_cnae"
                class="form-control"
                minLength="7"
                maxLength="7"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.codigo_cnae "
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="natureza_operacao">Natureza da Operação *</label>
            <validation-provider
              name="natureza da operação"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <multiselect
                id="natureza_operacao"
                :options="nauturezaOperacaoList"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                v-model="accountData.natureza_operacao"
                placeholder="Selecionar"
                class="with-border"
                :class="{ error: errors.length > 0 && dirty }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>

                <template slot="noOptions"> Nenhuma opção </template>

                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.natureza_operacao"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="form-group">
            <label for="natureza_operacao">Regime Especial de Tributação</label>
            <Select
              v-model="accountData.regime_especial_tributacao"
               id="regime_especial_tributacao"
              :options="regimeTributacaoEspecialList"
              @change="setRegimeEspecialTributario"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <div class="form-group">
            <label for="name">
              Item da lista de Serviços *
              <span id="help-icon-item" class="icon-box">
                <v-help-circle class="icon stroke" />
              </span>
              <b-tooltip target="help-icon-item" placement="bottom">
                Código municipal que informa a natureza da atividade de serviço
                prestada. Lista disponível no site da prefeitura da sua cidade
              </b-tooltip>
            </label>
            <validation-provider
              name="item da lista de serviços"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <input
                autocomplete="off"
                id="item_lista_servico"
                type="number"
                placeholder="11104"
                class="form-control"
                v-model="accountData.item_lista_servico"
                :class="{ error: errors.length > 0 && dirty }"
              />
              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.item_lista_servico"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <validation-provider
              name="aliquota"
              rules="required"
              v-slot="{ errors, touched, dirty }"
            >
              <label for="aliquota">Alíquota *</label>
              <the-mask
                autocomplete="off"
                id="aliquota"
                type="text"
                masked
                class="form-control"
                placeholder="00,00%"
                v-model="accountData.aliquota"
                @input="formatAliquota($event)"
                :mask="['#%', '#,#%', '#,##%', '##,##%']"
                :class="{ error: errors.length > 0 && dirty }"
              />

              <span class="error-message" v-if="touched && errors.length > 0">
                {{ errors[0] }}
              </span>
              <span
                class="error-message"
                v-if="validated && !touched && !accountData.aliquota"
              >
                Campo obrigatório
              </span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="auto" class="toggle-button__container">
          <toggle-button
            :sync="true"
            :height="24"
            @input="setOptanteNacional"
            v-model="accountData.optante_simples_nacional"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          />
          <p class="mb-0 ml-2">Optante pelo Simples Nacional</p>
        </b-col>
        <b-col cols="auto" class="toggle-button__container">
          <toggle-button
            :sync="true"
            :height="24"
            @input="setISSretido"
            v-model="accountData.iss_retido"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          />
          <p class="mb-0 ml-2">ISS Retido</p>
        </b-col>
      </b-row>
    </validation-observer>

    <ConfirmEditionModal @saveAccountData="saveAccountData" />
  </div>
</template>

<script>
import * as cep from 'cep-promise'
import api from '@/modules/nfe/api'
import { mapState } from 'vuex'
import { numberWithoutPercent } from '@/utils/dataHelper.js'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    ConfirmEditionModal: () =>
      import('@/modules/nfe/modals/ConfirmEditionModal'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Autocomplete: () => import('@/components/Autocomplete'),
    Close: () => import('@/assets/icons/close.svg'),
    Clip: () => import('@/assets/icons/clip2.svg'),
    Select:() => import('@/components/Select')
  },
  computed: {
    ...mapState('nfe', ['CNPJForm', 'isEdit', 'showCNPJForm'])
  },
  mounted() {
    if (this.CNPJForm) {
      this.accountData = {
        id: this.CNPJForm.id,
        cnpj: this.CNPJForm.cnpj,
        razao_social: this.CNPJForm.razao_social,
        nome_fantasia: this.CNPJForm.nome_fantasia,
        inscricao_estadual: this.CNPJForm.inscricao_estadual,
        inscricao_municipal: this.CNPJForm.inscricao_municipal,
        regime_tributario: this.CNPJForm.regime_tributario,
        email: this.CNPJForm.email,
        telefone: this.CNPJForm.telefone,
        cep: this.CNPJForm.cep,
        logradouro: this.CNPJForm.logradouro,
        numero: this.CNPJForm.numero,
        complemento: this.CNPJForm.complemento,
        bairro: this.CNPJForm.bairro,
        municipio: this.CNPJForm.municipio,
        uf: this.CNPJForm.uf,
        nome_responsavel: this.CNPJForm.nome_responsavel,
        cpf_responsavel: this.CNPJForm.cpf_responsavel,
        iss_retido: this.CNPJForm.config.iss_retido,
        aliquota: this.CNPJForm.config.aliquota,
        optante_simples_nacional: this.CNPJForm.config.optante_simples_nacional,
        natureza_operacao: this.CNPJForm.config.natureza_operacao,
        codigo_cnae: this.CNPJForm.config.codigo_cnae,
        item_lista_servico: this.CNPJForm.config.item_lista_servico,
        ativo: this.CNPJForm.ativo,
        login_responsavel: this.CNPJForm.config.login_responsavel,
        cpf_cnpj_contabilidade: this.CNPJForm.cpf_cnpj_contabilidade,
        regime_especial_tributacao: this.CNPJForm.regime_especial_tributacao
      }

      const objectWithoutNulls = Object.keys(this.accountData).reduce(
        (result, key) => {
          if (this.accountData[key] !== null) {
            result[key] = this.accountData[key]
          }
          return result
        },
        {}
      )

      this.accountData = objectWithoutNulls
    } else {
      this.accountData = this.getDefaultData()
    }
  },
  beforeDestroy() {
    this.$store.dispatch('nfe/updateCNPJForm', null)
  },
  data() {
    return {
      touched: false,
      regimeTributarioOptions: [
        { label: 'Simples Nacional', value: 1 },
        {
          label: 'Simples Nacional – excesso de sublimite de receita bruta',
          value: 2
        },
        { label: 'Regime Normal', value: 3 }
      ],
      base64Data: null,
      fileName: '',
      validated: false,
      showContadorBox: false,
      statesOptions: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
      ],
      nauturezaOperacaoList: [
        'Tributação no município',
        'Tributação fora do município',
        'Isenção',
        'Imune',
        'Exigibilidade suspensa por decisão judicial',
        'Exigibilidade suspensa por procedimento administrativo'
      ],
      regimeTributacaoEspecialList: [
        {value:0, label:'Tributação Normal'},
        {value:1, label:'Microempresa Municipal (ME)'},
        {value:2, label:'Estimativa'},
        {value:3, label:'Sociedade de Profissionais'},
        {value:4, label:'Cooperativa'},
        {value:5, label:'Microempresário Individual (MEI)'},
        {value:6, label:'Empresário de Pequeno Porte (EPP)'},
        {value:7, label:'Autônomo'},
        {value:8, label:'Média Empresa'},
        {value:9, label:'Grande Empresa'},
        {value:10, label:'Empresa Individual de Responsabilidade Limitada (EIRELI)'},
      ],
      accountData: this.getDefaultData()
    }
  },
  methods: {
    numberWithoutPercent,
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          const base64Data = reader.result
          resolve(base64Data)
        }
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async uploadCertificadoDigital(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        const isFileValid = await this.$refs.certificadoDigital.validate(
          selectedFile
        )

        if (!isFileValid?.valid) {
          this.$refs.certificadoDigital.applyResult({
            errors: ["O arquivo deve ser do tipo 'p12', 'pfx', 'pem' ou 'crt'"],
            valid: false,
            failedRules: {}
          })
        } else {
          this.$refs.certificadoDigital.applyResult({
            errors: [],
            valid: true,
            failedRules: {}
          })
        }

        this.base64Data = await this.convertFileToBase64(selectedFile)
        const base64Extracted = this.base64Data.split(',')[1]
        this.fileName = selectedFile.name
        this.accountData.arquivo_certificado_base64 = base64Extracted
      }
    },
    handleFileInputClick() {
      this.$refs.fileInput.click()
    },
    deleteCertificadoDigital() {
      this.fileName = ''
      this.base64Data = null
      this.$refs.fileInput.value = ''
      this.accountData.arquivo_certificado_base64 = ''
    },
    async searchCEP() {
      if (this.accountData.cep.length === 9) {
        try {
          const res = await cep(this.accountData.cep)

          this.accountData.logradouro = res.street
          this.accountData.bairro = res.neighborhood
          this.accountData.municipio = res.city
          this.accountData.uf = res.state

          this.$refs.addressNumber.focus()
        } catch (err) {
          console.error('Err', err)
          this.$toast.error('CEP inválido')
        }
      }
    },
    openContador() {
      this.showContadorBox = !this.showContadorBox
    },
    setRegimeTriburaio($event) {
      this.accountData.regime_tributario = $event
    },
    setRegimeEspecialTributario($event) {
      this.accountData.regime_especial_tributacao = $event
    },
    setOptanteNacional($event) {
      this.accountData.optante_simples_nacional = $event
    },
    formatAliquota($event) {
      const aliquota = numberWithoutPercent($event)

      if (aliquota === '1') {
        this.accountData.aliquota = 1.0
      }

      this.accountData.aliquota = aliquota
    },
    setISSretido($event) {
      this.accountData.iss_retido = $event
    },
    async isValid() {
      this.validated = true

      let isValid = true;
        for (const key in this.accountData) {
          if (!this.accountData[key] && !['inscricao_estadual', 'telefone', 'complemento', 
            'login_prefeitura', 'senha_preitura', 'iss_retido', 'optante_simples_nacional', 
            'login_responsavel', 'cpf_cnpj_contabilidade', 'regime_especial_tributacao', 'ativo'].some(value => value === key)) {
            isValid = false;
            const formattedKey = key.replace(/_/g, ' ');
            this.$toast.warning(`Campo ${formattedKey} inválido.`);
          }
        }

      const objectWithoutNulls = Object.keys(this.accountData).reduce(
        (result, key) => {
          if (this.accountData[key] !== null && this.accountData[key] !== '') {
            result[key] = this.accountData[key]
          }
          return result
        },
        {}
      )

      this.accountData = objectWithoutNulls



      return isValid
    },
    cancelForm() {
      this.$bvModal.show('confirm-edition-modal')
    },
    async saveAccountData() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      const isLoading = this.$loading.show()

      try {
        // Remove the 'cnpj' property from accountData
        const { cnpj, ...accountDataWithoutCNPJ } = this.accountData

        await api.updateCompany(this.accountData.id, {
          ...accountDataWithoutCNPJ,
          cep: this.accountData.cep.replace('-', ''),
          aliquota: this.accountData.aliquota
        })

        this.$toast.success('Dados alterados com sucesso!')
        this.accountData = {}
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
        this.accountData = {}
      }
    },
    async saveCNPJForm() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      if (this.isEdit) {
        const isLoading = this.$loading.show()

        try {
          // Remove the 'cnpj' property from accountData
          const { cnpj, ...accountDataWithoutCNPJ } = this.accountData

          await api.updateCompany(this.accountData.id, {
            ...accountDataWithoutCNPJ,
            clinic_id: getCurrentClinic().id,
            cep: this.accountData.cep?.replace('-', ''),
            aliquota: this.accountData.aliquota
          })

          this.$store.dispatch('nfe/updateShowCNPJForm', false)
          this.$toast.success('Dados alterados com sucesso!')
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          isLoading.hide()
          this.accountData = {}
        }
      } else {
        const objectWithoutNulls = Object.keys(this.accountData).reduce(
          (result, key) => {
            if (
              this.accountData[key] !== null &&
              this.accountData[key] !== ''
            ) {
              result[key] = this.accountData[key]
            }
            return result
          },
          {}
        )

        const isLoading = this.$loading.show()

        try {
          await api.createCompany({
            ...objectWithoutNulls,
            clinic_id: getCurrentClinic().id,
            cnpj: this.accountData.cnpj
              .replace('.', '')
              .replace('.', '')
              .replace('/', '')
              .replace('-', ''),
            cpf_responsavel: this.accountData.cpf_responsavel
              .replace('.', '')
              .replace('.', '')
              .replace('-', ''),
            cep: this.accountData.cep.replace('-', ''),
            aliquota: this.accountData.aliquota
          })

          this.$store.dispatch('nfe/updateShowCNPJForm', false)
          this.$toast.success('CNPJ cadastrado com sucesso!')
          this.accountData = {}
        } catch (err) {
          if (err.response?.data?.error?.errors) {
            this.$refs.createNfForm.setErrors(err.response.data.error.errors)
          }
          this.$toast.error(err.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    async deleteAccount() {
      const res = await this.$swal({
        title: '',
        html: `<div class="body-alert">Ao deletar esta conta, essa ação <b>não poderá ser desfeita!</b> Deseja realmente cancelar esse CNPJ?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        closeOnClickOutside: false,
        reverseButtons: true
      })

      if (res.isConfirmed) {
        const isLoading = this.$loading.show()

        try {
          await api.deleteCompany(this.accountData?.id)

          const res = await api.getCompanies(getCurrentClinic().id)
          this.registeredAccounts = res.data

          this.$store.dispatch('nfe/updateShowCNPJForm', false)
          this.$toast.success('Conta deletada com sucesso!')
        } catch (err) {
          console.error(err)
          this.$toast.error(err.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    getDefaultData() {
      return {
        cnpj: '',
        razao_social: '',
        nome_fantasia: '',
        inscricao_municipal: '',
        inscricao_estadual: '',
        regime_tributario: '',
        email: '',
        telefone: '',

        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        municipio: '',
        uf: '',

        nome_responsavel: '',
        cpf_responsavel: '',
        cpf_cnpj_contabilidade: '',
        login_prefeitura: '',
        senha_preitura: '',

        codigo_cnae: '',
        natureza_operacao: '',
        item_lista_servico: '',
        aliquota: '',
        optante_simples_nacional: false,
        iss_retido: false,
        regime_especial_tributacao: ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';

#cnpj-form {
  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 12px;

    .chevron {
      width: 14px;
      height: 14px;
      stroke: var(--neutral-600);
      margin: 0 4px;
    }

    span {
      cursor: pointer;

      &.active {
        color: var(--blue-500);
        font-weight: 500;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;

    .delete-button {
      color: var(--states-error);
      margin-right: 1rem;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .subtitle {
    margin: 1rem 0;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Nunito Sans';
  }

  .error-message {
    padding-left: $padding-xxs;

    &.w-130px {
      width: 130px;
    }
  }

  .contador-link {
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: var(--blue-500);
  }

  .input-file__label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-500);
  }

  .input-file__label--selected {
    border-radius: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: $padding-xxs $padding-xs;
    background: var(--neutral-100);
  }

  .close__container {
    background: var(--neutral-200);
    border-radius: 50%;
    height: 16px;
    width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 14px;
    top: 46%;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  #help-icon-item .icon {
    width: 16px;
    height: 16px;
  }

  .toggle-button__container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
}
</style>
